<template>
  <div>
    <el-form ref="form" :model="form" inline label-width="0" size="small">
      <el-form-item prop="appName">
        <el-input
          v-model="form.appName"
          :placeholder="$t('order.system') + $t('title.Name')"
          clearable
        />
      </el-form-item>
      <el-form-item prop="exportType">
        <el-input
          v-model="form.exportType"
          :placeholder="$t('page.export') + $t('title.types')"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button :loading="queryLoading" type="primary" @click="handleQuery">
          {{ $t('page.search') }}
        </el-button>
        <el-button>{{ $t('page.reset') }}</el-button>
      </el-form-item>
    </el-form>
    <el-button
      size="small"
      style="margin-bottom: 18px"
      type="primary"
      @click="handleOpenDetailDialog(null, 'add')"
    >
      新增
    </el-button>
    <el-table ref="table" v-loading="queryLoading" :data="tableData" :max-height="maxHeight" border size="small" stripe>
      <el-table-column :label="$t('order.system') + $t('title.Name')" align="center" prop="appName" />
      <el-table-column :label="$t('page.export') + $t('title.types')" align="center" prop="exportType" />
      <el-table-column :label="$t('export.exportDescription')" align="center" prop="exportDesc" />
      <el-table-column align="center" label="rpc地址" prop="reqAddress" />
      <el-table-column align="center" label="rpc方法" prop="reqMethod" />
      <el-table-column align="center" label="rpc入参类名" prop="reqClass" />
      <el-table-column align="center" label="页数字段" prop="reqPageNum" />
      <el-table-column align="center" label="分页大小字段" prop="reqPageSize" />
      <el-table-column :label="$t('page.operate')" align="center">
        <template #default="{ row }">
          <el-button size="small" type="text" @click="handleOpenDetailDialog(row)">
            {{ $t('order.Detail') }}
          </el-button>
          <el-button size="small" type="text" @click="handleOpenDetailDialog(row, 'update')">
            {{ $t('page.modify') }}
          </el-button>
          <el-button size="small" type="text" @click="handleDel(row)">
            {{ $t('page.delete') }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <paging ref="pager" v-loading="queryLoading" :pager="pager" end @update="handlePagerUpdate" />
    <detail-dialog
      :row.sync="currentRow"
      :type="detailDialogType"
      :visible.sync="detailDialogVisible"
      @refresh="handleQuery"
    />
  </div>
</template>

<script>
import Paging from '@/components/Paging/index.vue'
import { deleteConfig, getExportConfigList } from './api'
import { debounceGetTableMaxHeight } from '@/utils'
import DetailDialog from './DetailDialog.vue'

export default {
  name: 'ExportConfig',
  components: {
    DetailDialog,
    Paging
  },
  data() {
    return {
      form: {
        appName: '',
        exportType: ''
      },
      pager: {
        size: 10,
        current: 1,
        total: 0
      },
      queryLoading: false,
      tableData: [],
      maxHeight: 0,
      currentRow: null,
      detailDialogVisible: false,
      detailDialogType: 'add'
    }
  },
  computed: {
    queryParams() {
      return {
        ...this.form,
        current: this.pager.current,
        size: this.pager.size
      }
    }
  },
  watch: {
    form: {
      handler() {
        this.queryedChange = true
      },
      deep: true
    }
  },
  created() {
    this.debounceGetTableMaxHeight = debounceGetTableMaxHeight.bind(this)
    this.handleQuery()
  },
  mounted() {
    this.debounceGetTableMaxHeight()
    window.addEventListener('resize', this.debounceGetTableMaxHeight)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.debounceGetTableMaxHeight)
  },
  methods: {
    async handleQuery() {
      this.queryLoading = true
      if (this.queryedChange) {
        this.pager.current = 1
      }
      const { datas } = await getExportConfigList(this.queryParams).finally(() => {
        this.queryLoading = false
        this.queryedChange = false
      })
      this.tableData = datas?.list || []
      this.pager.total = datas?.totalCount || 0
    },
    handlePagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    },
    handleDel(row) {
      this.$confirm('确定删除该配置吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.cancelButtonLoading = false
            const { msg } = await deleteConfig(row.id).finally(() => {
              instance.confirmButtonLoading = false
              instance.cancelButtonLoading = false
            })
            this.$message.success(msg)
            done()
            await this.handleQuery()
          }
          done()
        }
      })
    },
    handleOpenDetailDialog(row, type = 'detail') {
      this.detailDialogType = type
      this.currentRow = row
      this.detailDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
