<template>
  <el-dialog
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :visible.sync="visible"
    title="基础信息"
    top="5vh"
    width="80%"
  >
    <el-form
      ref="form"
      :disabled="isDetail"
      :model="form"
      :rules="rules"
      label-position="left"
      label-width="15px"
      size="small"
    >
      <el-row :gutter="15">
        <el-col :lg="8" :sm="12" :xl="6" :xs="24">
          <el-form-item label=" " prop="appName">
            <el-input v-model="form.appName" :disabled="!!row" :placeholder="$t('order.system') + $t('title.Name')" />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="12" :xl="6" :xs="24">
          <el-form-item label=" " prop="exportType">
            <el-input v-model="form.exportType" :disabled="!!row" :placeholder="$t('page.export') + $t('title.types')" />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="12" :xl="6" :xs="24">
          <el-form-item label=" " prop="exportDesc">
            <el-input v-model="form.exportDesc" :placeholder="$t('export.exportDescription')" />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="12" :xl="6" :xs="24">
          <el-form-item label=" " prop="reqAddress">
            <el-input v-model="form.reqAddress" placeholder="rpc地址" />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="12" :xl="6" :xs="24">
          <el-form-item label=" " prop="reqMethod">
            <el-input v-model="form.reqMethod" placeholder="rpc方法" />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="12" :xl="6" :xs="24">
          <el-form-item label=" " prop="reqClass">
            <el-input v-model="form.reqClass" placeholder="rpc入参类名" />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="12" :xl="6" :xs="24">
          <el-form-item label=" " prop="reqPageNum">
            <el-input v-model="form.reqPageNum" placeholder="页数字段" />
          </el-form-item>
        </el-col>
        <el-col :lg="8" :sm="12" :xl="6" :xs="24">
          <el-form-item label=" " prop="reqPageSize">
            <el-input v-model="form.reqPageSize" placeholder="分页大小字段" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-divider />
      <div style="font-size: 18px;color:#303133;margin-bottom: 24px">导出字段信息</div>
      <div style="max-height: 320px;overflow-y: auto">
        <div v-for="(item, index) in form.exportColumnConfig" :key="index" style="display:flex;align-items: flex-start">
          <div style="flex: 1">
            <el-row :gutter="15">
              <el-col :lg="8" :sm="12" :xl="6" :xs="24">
                <el-form-item
                  :prop="'exportColumnConfig.'+index+'.columnName'"
                  :rules="{ required: true, message: '请输入字段名称', trigger: 'blur' }"
                  label=" "
                >
                  <el-input v-model="item.columnName" placeholder="字段名称" />
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="12" :xl="6" :xs="24">
                <el-form-item
                  :prop="'exportColumnConfig.'+index+'.columnSort'"
                  :rules="{ required: true, message: '请输入字段排序', trigger: 'blur' }"
                  label=" "
                >
                  <el-input v-model="item.columnSort" placeholder="字段排序" />
                </el-form-item>
              </el-col>
              <el-col :lg="8" :sm="12" :xl="6" :xs="24">
                <el-form-item
                  :prop="'exportColumnConfig.'+index+'.filedName'"
                  :rules="{ required: true, message: '请输入字段名称', trigger: 'blur' }"
                  label=" "
                >
                  <el-input v-model="item.filedName" placeholder="字段名称" />
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <div style="width: 80px;margin: 0 15px;white-space: nowrap">
            <el-button circle icon="el-icon-plus" size="small" @click="handleAddCol(index)" />
            <el-button v-if="index !== 0" circle icon="el-icon-minus" size="small" type="danger" @click="handleDelCol(index)" />
          </div>
        </div>
      </div>
    </el-form>
    <template #footer>
      <el-button size="small" @click="handleClose">取 消</el-button>
      <el-button :loading="submitLoading" size="small" type="primary" @click="handleSubmit">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { addConfig, updateConfig } from '@/views/async-export/export-config/api'

export default {
  name: '',
  props: {
    type: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    row: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      submitLoading: false,
      form: {
        appName: '',
        exportType: '',
        exportDesc: '',
        reqAddress: '',
        reqMethod: '',
        reqClass: '',
        reqPageNum: '',
        reqPageSize: '',
        exportColumnConfig: [{
          columnName: '',
          columnSort: '',
          filedName: ''
        }]
      },
      rules: {
        appName: [{
          required: true,
          message: '请输入系统名称',
          trigger: 'blur'
        }],
        exportType: [{
          required: true,
          message: '请输入导出类型',
          trigger: 'blur'
        }],
        exportDesc: [{
          required: true,
          message: '请输入导出说明',
          trigger: 'blur'
        }],
        reqAddress: [{
          required: true,
          message: '请输入rpc地址',
          trigger: 'blur'
        }],
        reqMethod: [{
          required: true,
          message: '请输入rpc方法',
          trigger: 'blur'
        }],
        reqClass: [{
          required: true,
          message: '请输入rpc入参类名',
          trigger: 'blur'
        }],
        reqPageNum: [{
          required: true,
          message: '请输入页数字段',
          trigger: 'blur'
        }],
        reqPageSize: [{
          required: true,
          message: '请输入分页大小字段',
          trigger: 'blur'
        }],
        exportColumnConfig: [{
          required: true,
          message: '请输入导出字段信息',
          trigger: 'blur'
        }]
      }
    }
  },
  computed: {
    isDetail() {
      return this.type === 'detail'
    },
    submitFn() {
      return this.row ? updateConfig : addConfig
    }
  },
  watch: {
    visible(val) {
      if (val) {
        if (this.row) {
          this.form = { ...this.row }
        }
      }
    }
  },
  methods: {
    handleAddCol(index) {
      this.form.exportColumnConfig.splice(index + 1, 0, {
        columnName: '',
        columnSort: '',
        filedName: ''
      })
    },
    handleDelCol(index) {
      this.form.exportColumnConfig.splice(index, 1)
    },
    handleClose() {
      if (this.submitLoading) {
        this.$message('正在执行中，请稍后关闭')
        return
      }
      this.form = {
        appName: '',
        exportType: '',
        exportDesc: '',
        reqAddress: '',
        reqMethod: '',
        reqClass: '',
        reqPageNum: '',
        reqPageSize: '',
        exportColumnConfig: [{
          columnName: '',
          columnSort: '',
          filedName: ''
        }]
      }
      this.$nextTick(() => {
        this.$refs.form.clearValidate()
      })
      this.$emit('update:row', null)
      this.$emit('update:visible', false)
    },
    handleSubmit() {
      if (this.isDetail) {
        this.handleClose()
        return
      }
      this.$refs.form.validate(async valid => {
        if (!valid) return
        this.submitLoading = true
        const { msg } = await this.submitFn(this.form).finally(() => {
          this.submitLoading = false
        })
        this.$message.success(msg)
        this.$emit('refresh')
        this.handleClose()
      })
    }
  }
}
</script>

<style scoped>
::v-deep .el-divider--horizontal {
  margin-top: 6px;
}

::v-deep .el-dialog__body {
  padding-top: 18px
}
</style>
