import request from '@/utils/oms-request'

export const getExportConfigList = (params) => {
  return request({
    url: 'oms-export/export-config/page',
    params
  })
}

export const addConfig = (data) => {
  return request({
    url: 'oms-export/export-config/save',
    method: 'post',
    data
  })
}

export const updateConfig = (data) => {
  return request({
    url: 'oms-export/export-config/update',
    method: 'post',
    data
  })
}

export const deleteConfig = (id) => {
  return request({
    url: `oms-export/export-config/delete/${id}`,
    method: 'delete'
  })
}
